import axios from 'axios';
import Auth from './Auth';

// ENV Types
const baseUrl = process.env.REACT_APP_BASE_URL;

export const Api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 5000,
});


Api.interceptors.request.use(
  (config) => {
    if (Auth.isAuthenticated()) {
      config.headers!['Authorization'] = `Bearer ${Auth.getToken()}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response.status;

    if (status === 401 || status === 403) {
      // Token is invalid or expired, redirect to login page
      Auth.logOut();
    }
    return Promise.reject(error);
  }
);
